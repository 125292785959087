<template>
  <el-row class="upload" :gutter="10">
    <el-col :span="11" class="search_input">
      <el-input placeholder="Search" v-model="searchValue" @keydown.enter="handleSearch">
        <template #prefix>
          <i class="iconfont icon-fangdajing"></i>
        </template>
        <!-- <template #suffix>
          <el-button @click="handleSearch" class="search_btn" size="small" type="primary">Search</el-button>
        </template> -->
      </el-input>
    </el-col>
    <el-col :span="7">
      <el-select @change="handleChange" clearable v-model="sortValue" placeholder="Select" class="upload_select">
        <el-option
            v-for="item in sortDataList"
            :key="item.title"
            :label="item.title"
            :value="`${item.field}#${item.value}`"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="6" class="upload_btn">
      <el-upload
          action="/"
          :before-upload="handleBeforeUpload"
          :show-file-list="false"
      >
        <el-button type="primary" class="btn" :loading="store.state.FileLibrary.uploadLoading">Upload</el-button>
      </el-upload>
    </el-col>
  </el-row>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {getLibrarySortSelect} from '@/api/fileLibrary.js'
import {ElMessage} from "element-plus";
import {useStore} from "vuex";
import useFileUpload from "@/views/fileLibrary/hooks/useFileUpload";

const store = useStore()
//#region init sort data
const sortDataList = ref([])
const getSortSelect = async () => {
  try {
    const {data} = await getLibrarySortSelect()
    if (data && Array.isArray(data)) {
      sortDataList.value = data
    }
  } catch (e) {
    ElMessage.error(e)
  }
}
onMounted(() => {
  getSortSelect()
})
//endregion

const searchValue = computed({
  get() {
    return store.state.FileLibrary.searchValue.trim()
  },
  set(val) {
    store.commit('FileLibrary/setSearchValue', val.trim())
  }
})

const sortValue = computed({
  get() {
    return store.state.FileLibrary.sortValue.trim()
  },
  set(val) {
    store.commit('FileLibrary/setSortValue', val.trim())
  }
})

const handleSearch = () => {
  store.dispatch('FileLibrary/getImageList', 1)
}

const handleChange = handleSearch

const {beforeUpload} = useFileUpload()

const handleBeforeUpload = (file) => {
  beforeUpload(file, {
    uploadSuccess: () => {
      store.dispatch('FileLibrary/getImageList', 1)
    },
  })
  return false
}

</script>

<style lang="scss" scoped>
.upload {
  margin: 40px 0;

  .search_input {
    :deep(.el-input__inner) {
      padding-left: 45px !important;
      padding-right: 130px;
      position: relative;
    }
  }

  :deep(.el-input__suffix) {
    top: 3px;
    right: 3px;
    position: absolute;
  }

  .search_btn {
    width: 103px;
    height: 34px;
  }

  .icon-fangdajing {
    margin-left: 10px;
  }

  &_select {
    width: 100%;
    margin-left: 10px;
  }

  &_btn {
    text-align: right;

    .btn {
      width: 100%;
    }
  }
}
</style>
