<template>

  <CreateFolderDialog @refresh="refresh" :parentId="props.parentId" v-model="showCreateFolderDialog"/>

  <div class="assembly_create_new_folder" @click="showCreateFolderDialog = true">
    <div class="assembly_create_new_folder_inner">
      <span class="add_icon">
        <svg fill="#1EAB7F"
             t="1641814169490" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="3732" xmlns:xlink="http://www.w3.org/1999/xlink" width="21" height="21"><path
            d="M514.234182 21.597091c67.770182 0 131.630545 12.846545 191.581091 38.539636a495.709091 495.709091 0 0 1 156.951272 105.565091 495.709091 495.709091 0 0 1 105.565091 156.951273c25.693091 59.950545 38.539636 123.810909 38.539637 191.581091 0 68.514909-12.846545 132.561455-38.539637 192.139636a497.477818 497.477818 0 0 1-105.565091 156.392727 495.709091 495.709091 0 0 1-156.951272 105.565091c-59.950545 25.693091-123.810909 38.539636-191.581091 38.539637-68.514909 0-132.747636-12.846545-192.698182-38.539637a495.709091 495.709091 0 0 1-156.951273-105.565091c-44.683636-44.683636-79.872-96.814545-105.565091-156.392727C33.326545 646.795636 20.48 582.749091 20.48 514.234182c0-67.770182 12.846545-131.630545 38.539636-191.581091A495.709091 495.709091 0 0 1 164.584727 165.701818a495.709091 495.709091 0 0 1 156.951273-105.565091 483.979636 483.979636 0 0 1 192.698182-38.539636z m281.506909 560.779636c19.362909 0 35.746909-6.888727 49.152-20.666182 13.405091-13.777455 20.107636-30.347636 20.107636-49.710545 0-19.362909-6.702545-35.746909-20.107636-49.152a66.839273 66.839273 0 0 0-49.152-20.107636h-210.013091V232.727273c0-19.362909-6.888727-35.933091-20.666182-49.710546a67.770182 67.770182 0 0 0-49.710545-20.666182c-19.362909 0-35.746909 6.888727-49.152 20.666182A68.747636 68.747636 0 0 0 446.091636 232.727273v210.013091H236.078545c-19.362909 0-35.746909 6.702545-49.152 20.107636-13.405091 13.405091-20.107636 29.789091-20.107636 49.152 0 19.362909 6.702545 35.933091 20.107636 49.710545 13.405091 13.777455 29.789091 20.666182 49.152 20.666182h210.013091V791.272727c0 19.362909 6.702545 35.933091 20.107637 49.710546 13.405091 13.777455 29.789091 20.666182 49.152 20.666182 19.362909 0 35.933091-6.888727 49.710545-20.666182 13.777455-13.777455 20.666182-30.347636 20.666182-49.710546v-208.896h210.013091z"
            p-id="3733"></path>
        </svg>
      </span>
      <span class="text">Create new folder</span>
    </div>
  </div>
</template>

<script setup>
import CreateFolderDialog from '@/views/fileLibrary/components/CreateFolderDialog'
import {ref} from "vue";
import {useStore} from "vuex";

const $store = useStore()

const props = defineProps({
  parentId: {
    type: Number,
    default: 0
  }
})

const showCreateFolderDialog = ref(false)

const refresh = () => {
  $store.dispatch('FileLibrary/getFolderTreeData', true)
}

</script>

<style scoped lang='scss'>
.assembly_create_new_folder {
  width: 202px;
  height: 80px;
  border-radius: 3px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;

  &_inner {
    height: 100%;
    display: flex;
    align-items: center;

    .add_icon {
      margin: 0 14px 0 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }

    .text {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      user-select: none;
    }
  }

  &:active {
    border: 1px solid var(--el-color-primary);
  }
}
</style>
