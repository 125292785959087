<template>
  <div>
    <el-dialog
        custom-class="previewDialog"
        v-model="dialogVisible"
        width="460px"
        :close-on-click-modal="false"
    >
      <template #title>
        <div class="title">Are you sure?</div>
      </template>

      <div class="_body">

        <div class="text" v-if="!isFile">Really delete this folder from your library?<br/>This cannot be undone!</div>
        <div class="text" v-else>Really delete the selected files from your library?<br/>This cannot be undone!</div>

        <div class="check">
          <el-checkbox class="check_box" v-model="check" size="large"/>
          <div v-if="!isFile" class="check_text" @click="check = !check" :class="{check_text_active: check}">Yes, I
            understand that all
            data in folders and all<br/>sub
            folders will be lost and
            this action does not<br/>have a revert possibility
          </div>

          <div v-else class="check_text" @click="check = !check" :class="{check_text_active: check}">Yes, I know that
            all selected files will be lost
            <br/>and this action does not have a revert possibility
          </div>
        </div>

      </div>


      <template #footer>
        <div class="assembly_dialog_create_folder_footer">
          <el-button :loading="loadingCreate" @click="handleDelete" type="primary">
            {{ !isFile ? 'Delete folder' : 'Delete files' }}
          </el-button>
          <el-button type="info" @click="handleCancel" class="cancelBtn">Cancel</el-button>
        </div>
      </template>

    </el-dialog>
  </div>
</template>

<script setup>
import useDialogModelValue from "@/views/fileLibrary/hooks/useDialogModelValue";
import {ref, toRef} from "vue";
import {ElMessage} from "element-plus";
import {delAlbum, deleteBatchImage} from '@/api/fileLibrary'
import {useStore} from "vuex";

const $store = useStore()
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  deleteFolderId: {
    type: Number,
    default: null,
  },
  isFile: {
    type: Boolean,
    default: false
  },
  selectedFilesIds: {
    type: Array,
    default: () => ([]),
  }
})
const emit = defineEmits(['update:modelValue', 'update:deleteFolderId', 'clearFileSuccess'])
//弹窗open|close
const {dialogVisible} = useDialogModelValue(toRef(props, 'modelValue'), emit)

const check = ref(false)

const loadingCreate = ref(false)
const handleDelete = async () => {
  if (!check.value) {
    ElMessage.error('You must confirm the deletion and check it!')
    return
  }
  try {
    loadingCreate.value = true

    if (!props.isFile) {
      await delAlbum(props.deleteFolderId)
      $store.dispatch('FileLibrary/getFolderTreeData', true)
    } else {
      await deleteBatchImage(props.selectedFilesIds)
      emit('clearFileSuccess')
      $store.dispatch('FileLibrary/getImageList', 1)
    }
    handleCancel()
  } catch (e) {
    ElMessage.error(e)
  } finally {
    loadingCreate.value = false
  }
}
const handleCancel = () => {
  check.value = false
  loadingCreate.value = false
  emit('update:modelValue', false)
  emit('update:deleteFolderId', null)
}
</script>

<style scoped lang='scss'>
.title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
}

._body {
  .text {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    line-height: 1.2em;
  }

  .check {
    margin-top: 30px;
    display: flex;
    cursor: pointer;
    position: relative;

    .check_box {
      margin-right: 10px;
      position: absolute;
      top: -10px;
    }

    .check_text {
      margin-left: 30px;
      line-height: 1.2em;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
    }

    .check_text_active {
      color: var(--el-color-primary);
      //color: red;
    }

  }
}
</style>
