<template>
  <div class="assembly_dialog_create_folder">
    <el-dialog
        custom-class="previewDialog"
        v-model="dialogVisible"
        width="600px"
        :close-on-click-modal="false"
        @closed="closed"
    >
      <template #title>
        <div class="__title__">{{ props.updateObj?.id ? 'Modify folder' : 'Create new folder' }}</div>
      </template>

      <div class="assembly_dialog_create_folder_body">
        <div class="assembly_dialog_create_folder_body_folder_name">
          <p class="assembly_dialog_create_folder_body_title">Folder name</p>
          <div class="assembly_dialog_create_folder_body_input_view">
            <el-input v-model="folderName"/>
          </div>
        </div>

        <div class="assembly_dialog_create_folder_body_avatar">
          <p class="assembly_dialog_create_folder_body_title">Avatar</p>
          <div class="assembly_dialog_create_folder_body_avatar_selector">
            <template v-for="item in avatarIcon" :key="item">
              <div @click="handleSetCurrentSelectAvatar(item)"
                   class="assembly_dialog_create_folder_body_avatar_selector_item"
                   :class="{'assembly_dialog_create_folder_body_avatar_selector_item_active':currentSelectAvatar === item}"
              >
                <i class="iconfont-avatar" :class="`${item}`"/>
              </div>
            </template>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="assembly_dialog_create_folder_footer">
          <el-button :loading="loadingCreate" @click="handleCreate" type="primary">{{ props.updateObj?.id ? 'Modify' : 'Create' }}</el-button>
          <el-button type="info" @click="handleCancel" class="cancelBtn">Cancel</el-button>
        </div>
      </template>

    </el-dialog>
  </div>
</template>

<script setup>

import {ref, toRef, watch} from "vue";
import useDialogModelValue from "@/views/fileLibrary/hooks/useDialogModelValue";
import useCurrentSelectAvatar from "@/views/fileLibrary/hooks/useCurrentSelectAvatar";
import {ElMessage} from "element-plus";
import {addAlbum, editAlbum_New} from '@/api/fileLibrary'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  parentId: {
    type: Number,
    default: 0
  },
  updateObj: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(['update:modelValue', 'refresh', 'clear'])

//弹窗open|close
const {dialogVisible} = useDialogModelValue(toRef(props, 'modelValue'), emit)
//表单内容
const folderName = ref('')
const {avatarIcon, currentSelectAvatar, handleSetCurrentSelectAvatar, reduction} = useCurrentSelectAvatar()
const loadingCreate = ref(false)

//创建
const handleCreate = async () => {
  if (!folderName.value || folderName.value.length <= 0) {
    ElMessage.error('Folder name cannot be empty')
    return
  }
  if (!currentSelectAvatar.value || currentSelectAvatar.value.length <= 0) {
    ElMessage.error('Avatar cannot be empty')
    return
  }
  loadingCreate.value = true

  //判断是创建还是修改
  if (props.updateObj?.id) {
    //修改
    try {
      await editAlbum_New({
        parentId: props.parentId,
        icon: currentSelectAvatar.value,
        name: folderName.value,
        albumId: props.updateObj.id
      })
      emit('refresh')
      handleCancel()
    } catch (e) {
      ElMessage.error(e)
    } finally {
      loadingCreate.value = false
    }
  } else {
    //创建
    try {
      await addAlbum({
        name: folderName.value,
        icon: currentSelectAvatar.value,
        parentId: props.parentId,
      })
      emit('refresh')
      handleCancel()
    } catch (e) {
      ElMessage.error(e)
    } finally {
      loadingCreate.value = false
    }
  }
}
//清除
const handleCancel = () => {
  folderName.value = ''
  reduction()
  emit('update:modelValue', false)
}

//修改
const closed = () => {
  emit('clear')
}
watch(() => props.modelValue, () => {
  if (props.modelValue && props.updateObj?.id) {
    folderName.value = props.updateObj?.name
    currentSelectAvatar.value = props.updateObj?.icon
  }
})

</script>

<style scoped lang='scss'>
.__title__ {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
}

.assembly_dialog_create_folder_body {
  width: 100%;

  &_title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }

  &_input_view {
    margin-top: 9px;
  }

  &_avatar {
    margin-top: 30px;
  }

  &_avatar_selector {
    margin-top: 9px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &_item {
      cursor: pointer;
      border-radius: 3px;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 12px 12px 0;
      color: rgba(0, 0, 0, 0.4);
    }

    &_item_active {
      color: rgba(0, 0, 0, 0.85);
      border-color: rgba(0, 0, 0, 0.85);
    }

  }

}

.assembly_dialog_create_folder_footer {
  .cancelBtn {
    &:hover {
      .iconfont {
        color: var(--el-color-primary);
      }

      span {
        color: var(--el-color-primary);
      }
    }

    &:active {
      border-color: var(--el-color-primary) !important;
    }

    &:focus {
      color: rgba(0, 0, 0, 0.85);
    }

  }
}

:deep(.el-dialog) {
  display: flex;
  display: -ms-flex; /* 兼容IE */
  flex-direction: column;
  -ms-flex-direction: column; /* 兼容IE */
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);

  .el-dialog__body {
    max-height: 100%;
    flex: 1;
    -ms-flex: 1 1 auto; /* 兼容IE */
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.icon-wenjian-4, .icon-wenjian-20 {
  font-size: 16px;
}
</style>
