<template>

  <MoveFolderOrFilesDialog
      v-model="showMoveDialog"
      :folder-or-files="true"
      :move-ids="checkList"
      @moveSuccess="moveSuccess"
  />

  <ConfirmDeleteFolderDialog
      :is-file="true"
      @clearFileSuccess="handleClearFileSuccess"
      :selected-files-ids="checkList"
      v-model="showConfirmDeleteFolderDialog"/>

  <div v-loading="$store.state.FileLibrary.filesLoading && !$store.state.FileLibrary.loadingAtInit">
    <div class="title_view">
      <div class="title">Files in File library&nbsp;({{ $store?.state?.FileLibrary?.filesCount }})&nbsp;</div>
      <el-divider></el-divider>
    </div>

    <div class="actionBar">
      <el-checkbox class="checkBox" v-model="allSelect" label="Select all" size="large"></el-checkbox>
      <template v-if="checkList.length > 0">
        <el-button @click="handleMoveTo" size="small" class="btn" type="info">Move to</el-button>
        <el-button @click="handleDelete" size="small" class="btn" type="info">Delete</el-button>
        <el-button @click="handleDownload" size="small" class="btn" type="info">Download</el-button>
      </template>
    </div>

    <el-checkbox-group v-model="checkList">
      <div class="img_list">
        <template v-for="item in $store.state.FileLibrary.filesList">
          <div class="img_list_item">
            <FileItem :data-obj="item">
              <template #default="{ id }">
                <el-checkbox class="checkBox" :label="id" size="large"></el-checkbox>
              </template>
            </FileItem>
          </div>
        </template>
      </div>
    </el-checkbox-group>

    <div>
      <template v-if="$store.state.FileLibrary.filesList.length <= 0">
        <FilesInFileLibraryBlank/>
      </template>
    </div>

    <div class="paging_view">
      <el-pagination
          v-model:current-page="current"
          :total="$store.state.FileLibrary.filesCount"
          :page-size="8"
          :hide-on-single-page="true"
          background
          layout="prev, pager, next"/>
    </div>

  </div>
</template>

<script setup>
import ConfirmDeleteFolderDialog from '@/views/fileLibrary/components/ConfirmDeleteFolderDialog'
import FileItem from '@/views/fileLibrary/components/FileItem'
import FilesInFileLibraryBlank from '@/views/fileLibrary/components/FilesInFileLibraryBlank'
import {useStore} from "vuex";
import useFilesSelectAndOperation from "@/views/fileLibrary/hooks/useFilesSelectAndOperation";
import MoveFolderOrFilesDialog from '@/views/fileLibrary/components/MoveFolderOrFilesDialog'
import {computed} from "vue";

const $store = useStore()

const {
  checkList, allSelect,
  handleDelete, showConfirmDeleteFolderDialog, handleClearFileSuccess,
  handleDownload,
  handleMoveTo, showMoveDialog, moveSuccess,
} = useFilesSelectAndOperation()

const current = computed({
  get() {
    return $store.state.FileLibrary.filesCurrent
  },
  set(val) {
    $store.dispatch('FileLibrary/getImageList', Number(val))
  }
})

</script>

<style scoped lang='scss'>
:deep(.el-checkbox__label) {
  display: none;
}
.title {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

:deep(.el-divider) {
  margin: 20px 0;
  background: rgba(0, 0, 0, 0.1);
}

.actionBar {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .checkBox {
    margin-right: 30px;
  }

  .btn {
    color: #000;
    background-color: #e5e5e5;
    border-color: #E5E5E5;
    font-weight: 600;

    &:hover {
      color: #000;
      background-color: rgb(234, 234, 234);
    }

    &:active {
      color: #000;
      background-color: rgb(206, 206, 206);
    }
  }
}

.img_list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;

  &_item {
    margin: 0 20px 20px 0;

    .checkBox {
      position: absolute;
      left: 10px;
      top: 0;
    }

  }
}

.paging_view {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 30px 0;
}

</style>
