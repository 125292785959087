import {reactive, ref} from "vue";
import {useStore} from "vuex";

export default function () {
    const $store = useStore()
    //删除
    const showConfirmDeleteFolderDialog = ref(false)
    const deleteFolderId = ref(null)
    const showDeleteDialog = (id) => {
        deleteFolderId.value = id
        showConfirmDeleteFolderDialog.value = true
    }
    //移动
    const showMoveFolderDialog = ref(false)
    const moveId = ref(null)
    const showMoveToDialog = async (id) => {
        moveId.value = id
        showMoveFolderDialog.value = true
    }

    //编辑
    const showCreateFolderDialog = ref(false)
    const editData = reactive({
        icon: null,
        id: null,
        name: null
    })
    const showEditDialog = (obj) => {
        const {icon, id, name} = obj
        editData.id = id
        editData.icon = icon
        editData.name = name
        showCreateFolderDialog.value = true
    }

    const refresh = () => {
        $store.dispatch('FileLibrary/getFolderTreeData', true)
    }

    const clear = () => {
        editData.id = null
        editData.name = null
        editData.icon = null
    }

    const moveSuccess = () => {
        $store.dispatch('FileLibrary/getFolderTreeData', true)
    }

    return {
        //删除
        showDeleteDialog, showConfirmDeleteFolderDialog, deleteFolderId,
        //移动
        showMoveToDialog, showMoveFolderDialog, moveId, moveSuccess,
        //修改
        showEditDialog, showCreateFolderDialog, refresh, editData, clear,
    }
}
