//弹窗展示 开启和关闭
import {computed} from "vue";

export default function (modelValue, emit) {

    const dialogVisible = computed({
        get() {
            return modelValue.value
        },
        set(val) {
            emit('update:modelValue', val)
        }
    })

    return {
        dialogVisible
    }
}
