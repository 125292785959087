<template>
  <div v-if="recentlyUsedFiles.length > 0">
    <div class="title_view">
      <div class="title">Recently used files</div>
      <el-divider></el-divider>
    </div>

    <div class="recently_used_files_views">
      <template v-for="item in recentlyUsedFiles" :key="item.id">
        <div class="recently_used_files_item_view">
          <RecentlyUsedFilesItem :data-obj="item"/>
        </div>
      </template>
    </div>

  </div>
</template>

<script setup>
import RecentlyUsedFilesItem from './RecentlyUsedFilesItem'
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore()

const recentlyUsedFiles = computed(() => {
  return store.state.FileLibrary.recentlyUsedFiles
})
</script>

<style scoped lang='scss'>
.title {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

:deep(.el-divider) {
  margin: 20px 0;
  background: rgba(0, 0, 0, 0.1);
}

.recently_used_files_views {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: -20px;

  .recently_used_files_item_view {
    margin-bottom: 10px;
    margin-right: 20px;
    flex-shrink: 0;
  }
}
</style>
