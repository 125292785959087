import {ref} from "vue";
//iconfont-avatar
const avatarIcon = [
    "icon-wenjian-1",
    "icon-wenjian-2",
    "icon-wenjian-3",
    "icon-wenjian-4",
    "icon-wenjian-5",
    "icon-wenjian-6",
    "icon-wenjian-7",
    "icon-wenjian-8",
    "icon-wenjian-9",
    "icon-wenjian-10",
    "icon-wenjian-11",
    "icon-wenjian-12",
    "icon-wenjian-13",
    "icon-wenjian-14",
    "icon-wenjian-15",
    "icon-wenjian-16",
    "icon-wenjian-17",
    "icon-wenjian-18",
    "icon-wenjian-19",
    "icon-wenjian-20",
    "icon-wenjian-21"
]

export default function () {
    const currentSelectAvatar = ref(avatarIcon[0])

    const handleSetCurrentSelectAvatar = (iconString) => {
        currentSelectAvatar.value = iconString
    }

    //还原
    const reduction = () => {
        currentSelectAvatar.value = avatarIcon[0]
    }

    return {
        reduction,
        avatarIcon,
        currentSelectAvatar,
        handleSetCurrentSelectAvatar
    }
}
