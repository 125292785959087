<template>
  <div class="file-library" v-loading="$store.state.FileLibrary.loadingAtInit">
    <div class="w1120">
      <div class="title">File library</div>

      <SearchInput/>

      <RecentlyUsed/>

      <Folders/>

      <FilesInFileLibrary/>

    </div>

    <copyright-info style="margin-top: auto;"></copyright-info>
  </div>
</template>

<script setup>
import SearchInput from '@/views/fileLibrary/components/SearchInput'
import RecentlyUsed from '@/views/fileLibrary/components/RecentlyUsed'
import Folders from '@/views/fileLibrary/components/Folders'
import FilesInFileLibrary from '@/views/fileLibrary/components/FilesInFileLibrary'
import CopyrightInfo from '@/components/copyrightInfo.vue'
import {useStore} from "vuex";
import {onBeforeUnmount, onMounted} from "vue";

const $store = useStore()
onMounted(() => {
  $store.dispatch('FileLibrary/initData')
})

onBeforeUnmount(() => {
  $store.commit('FileLibrary/clearAll')
})

</script>

<style scoped lang='scss'>
@import '../myAccount/account.scss';

.file-library {
  width: 100%;
  display: flex;
  min-height: calc(100vh - 65px);
  flex-direction: column;
  background: #fff;

  .w1120 {
    margin: 0 auto;

    .title {
      margin-top: 49px;
      font-size: 36px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }


  }
}
</style>
