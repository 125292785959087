<template>
  <div class="folders_views"
       v-loading="$store.state.FileLibrary.loadingFolder && !$store.state.FileLibrary.loadingAtInit"
       ref="foldersRef"
  >

    <div class="title_view">
      <span class="title_view_title">Folders<span>&nbsp;({{
          $store?.state?.FileLibrary?.folderCount
        }})&nbsp;</span></span>
      <div class="assembly_folders_crumbs">
        <template v-for="(item,index) in $store?.state?.FileLibrary?.foldersCrumbs" :key="item.id">
          <div class="assembly_folders_crumbs_item" @click="removeItemsForFoldersCrumbs(index + 1)">
            <span class="name" :class="{'name_active':index === $store?.state?.FileLibrary?.foldersCrumbs.length - 1}">{{
                item.name
              }}</span>
            <template v-if="index !== $store?.state?.FileLibrary?.foldersCrumbs.length - 1">
              <span class="line">/</span>
            </template>
          </div>
        </template>
      </div>
    </div>

    <div class="folders_views_list" :class="{'folders_views_list_down':!openOrDown}">
      <div class="folders_views_list_item">
        <CreateNewFolder
            :parent-id="$store.state.FileLibrary.foldersCrumbs[$store.state.FileLibrary.foldersCrumbs.length - 1]?.id"/>
      </div>

      <template v-for="item in $store.getters['FileLibrary/foldersCurrent']" :key="item.id">
        <div class="folders_views_list_item">
          <FoldersItem
              :parent-id="$store.state.FileLibrary.foldersCrumbs[$store.state.FileLibrary.foldersCrumbs.length - 1]?.id"
              :data-obj="item"/>
        </div>
      </template>
    </div>

    <div class="open_or_down" v-if="isShow">
      <div @click="openOrDown = !openOrDown">
        <span>Show all folders</span>
        <el-icon class="icon_arrow" :class="{'down':openOrDown}">
          <arrow-down/>
        </el-icon>
      </div>
    </div>

  </div>
</template>

<script setup>
import FoldersItem from './FoldersItem'
import CreateNewFolder from './CreateNewFolder'
import {useStore} from "vuex";
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { throttle } from 'throttle-debounce';

const $store = useStore()

const removeItemsForFoldersCrumbs = (length) => {
  $store.commit('FileLibrary/deleteItemAtFoldersCrumbs', length)
}

let openOrDown = ref(false)

watch(() => $store.getters["FileLibrary/foldersCurrentId"], () => {
  $store.dispatch("FileLibrary/getImageList", 1)
})

const isShow = ref(false)
const foldersRef = ref()

watch(() => $store.getters['FileLibrary/foldersCurrent'], () => {
  if ($store.getters['FileLibrary/foldersCurrent'].length > 0) {
    if (foldersRef.value.offsetWidth < ($store.getters['FileLibrary/foldersCurrent'].length + 1) * 202) {
      isShow.value = true
      return
    }
  }
  isShow.value = false
})

const callback = () => {
  isShow.value = foldersRef.value.offsetWidth < ($store.getters['FileLibrary/foldersCurrent'].length + 1) * 202 ? true : false
}
// 节流函数
const throttleCallback = throttle(500, callback)

onMounted(() => {
  window.addEventListener('resize', throttleCallback)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', throttleCallback)
})
</script>

<style scoped lang='scss'>
.folders_views {
  margin-top: 40px;
  padding: 20px 15px;
  // width: calc(100% - 30px);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  margin-bottom: 40px;

  .title_view {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    &_title {
      margin-right: 20px;
      font-weight: 600;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: flex-start;
    margin-right: -20px;
    // justify-content: space-between;
    &_item {
      margin-bottom: 20px;
      flex-shrink: 1;
      margin-right: 20px;
    }

    &_item_blank {
      width: 202px;
      height: 0;
    }
  }

  &_list_down {
    max-height: 100px;
  }

  .show_all_folders_view {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_ {
      display: flex;
      align-items: center;
      margin-left: auto;

      .text {
        flex-shrink: 0;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }

      i {
        margin-left: 6px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
        transform: rotate(270deg);
        transition: all linear 0.3s;
      }

      .i_open {
        transform: rotate(90deg);
      }
    }

  }

}

.assembly_folders_crumbs {
  display: flex;

  &_item {
    .name {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      user-select: none;
      -webkit-user-drag: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .line {
      padding: 0 5px;
      user-select: none;
      -webkit-user-drag: none;
    }

    .name_active {
      cursor: default;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);

      &:hover {
        text-decoration: none;
      }
    }
  }

}

.open_or_down {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  display: flex;
  align-items: center;
  user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;
  justify-content: flex-end;

  div {
    display: flex;
    align-items: center;

    span {
      margin-right: 6px;
    }
  }

  .icon_arrow {
    transition: all linear .3s;
  }

  .down {
    transform: rotate(-180deg);
  }

}
</style>
