import {computed, ref} from "vue";
import {useStore} from "vuex";
import JsFileDownloader from "js-file-downloader";
import {ElMessage} from "element-plus";

export default function () {

    const store = useStore()
    const checkList = ref([])

    const allSelect = computed({
        get() {
            if (store.state.FileLibrary.filesList.length <= 0) {
                return false
            }
            return checkList.value.length === store.state.FileLibrary.filesList.length
        },
        set(val) {
            if (val) {
                checkList.value = store.state.FileLibrary.filesList.map(e => e.id)
            } else {
                checkList.value = []
            }
        },
    })

    const showConfirmDeleteFolderDialog = ref(false)
    const handleDelete = () => {
        showConfirmDeleteFolderDialog.value = true
    }

    const handleClearFileSuccess = () => {
        checkList.value = []
    }

    const handleDownload = async () => {
        const files = store.getters["FileLibrary/downLoadFiles"](checkList.value)
        const imgJsFileDownloaderArr = files.map(e => {
            return new JsFileDownloader({
                url: e?.larger?.img,
                filename: e.name,
            })
        })
        try {
            await Promise.all([imgJsFileDownloaderArr])
        } catch (e) {
            ElMessage.error('Download failed!')
        }
    }

    //移动
    const showMoveDialog = ref(false)
    const handleMoveTo = () => {
        showMoveDialog.value = true
    }

    const moveSuccess = () => {
        checkList.value = []
        store.dispatch('FileLibrary/getImageList', 1)
    }

    return {
        checkList, allSelect,
        handleDelete, showConfirmDeleteFolderDialog, handleClearFileSuccess,
        handleDownload,
        handleMoveTo, showMoveDialog, moveSuccess
    }
}
