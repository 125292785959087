import {ElMessage} from "element-plus";
import {useStore} from "vuex";
import file2base64 from "@/utils/file2base64";
import md5 from "md5";
import {judgeWhetherTheFileHasBeenUploadedByMd5, savePicture} from "@/api/fileLibrary";
import {segmentedUploadFile} from "@/utils/awsOss";

export default function () {
    /**
     *
     * @param file
     * @param callbacks
     *      progress?: (progress: number) => void,
     *      uploadSuccess?: (fileUrl: string, imageId: number) => void,
     *      uploadFail?: () => void,
     * @returns {boolean}
     */
    const beforeUpload = (file, callbacks) => {
        handleUpload(file, callbacks)
        return false
    }

    const store = useStore()

    const handleUpload = async (file, callbacks) => {
        const {type, size, name} = file
        if (type !== 'image/jpeg' && type !== 'image/png') {
            ElMessage.error('Only PNG or JPEG images are supported')
            if (!!callbacks && !!callbacks?.uploadFail && typeof callbacks.uploadFail === 'function') {
                callbacks.uploadFail()
            }
            return
        }
        if (size > 20 * 1024 * 1024) {
            ElMessage.error('Up to 20M files are supported')
            if (!!callbacks && !!callbacks?.uploadFail && typeof callbacks.uploadFail === 'function') {
                callbacks.uploadFail()
            }
            return
        }

        store.commit('FileLibrary/setUploadLoading', true)

        const base64 = await file2base64(file)
        const md5String = md5(base64)
        let res;
        try {
            const resData = await judgeWhetherTheFileHasBeenUploadedByMd5(md5String)
            res = resData.data
        } catch (e) {
            store.commit('FileLibrary/setUploadLoading', false)
            ElMessage.error(e)
            if (!!callbacks && !!callbacks?.uploadFail && typeof callbacks.uploadFail === 'function') {
                callbacks.uploadFail()
            }
            return
        }

        if (res.verify.result) {
            //上传
            try {
                const resData = await segmentedUploadFile(file, store.state.user.userInfo.userCode, md5String, progress => {
                    if (!!callbacks && !!callbacks?.progress && typeof callbacks.progress === 'function') {
                        callbacks.progress(progress)
                    }
                })
                const {Key, Location} = resData
                const {id: imageId} = await savePicture({
                    md5: md5String,
                    albumId: store.getters["FileLibrary/foldersCurrentId"],
                    larger: Key,
                    name
                })
                if (!!callbacks && !!callbacks?.uploadSuccess && typeof callbacks.uploadSuccess === 'function') {
                    callbacks.uploadSuccess(Location, imageId)
                }
            } catch (e) {
                ElMessage.error(e)
                if (!!callbacks && !!callbacks?.uploadFail && typeof callbacks.uploadFail === 'function') {
                    callbacks.uploadFail()
                }
            } finally {
                store.commit('FileLibrary/setUploadLoading', false)
            }
        } else {
            //已经存在
            const {larger, img} = res.verify.img_url
            try {
                const {id: imageId} = await savePicture({
                    md5: md5String,
                    albumId: store.getters["FileLibrary/foldersCurrentId"],
                    larger,
                    name: res.verify.name
                })
                if (!!callbacks && !!callbacks?.uploadSuccess && typeof callbacks.uploadSuccess === 'function') {
                    callbacks.uploadSuccess(img, imageId)
                }
            } catch (e) {
                ElMessage.error(e)
                if (!!callbacks && !!callbacks?.uploadFail && typeof callbacks.uploadFail === 'function') {
                    callbacks.uploadFail()
                }
            } finally {
                store.commit('FileLibrary/setUploadLoading', false)
            }
        }
    }

    return {
        beforeUpload
    }

}
