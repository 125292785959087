<template>

  <MoveFolderOrFilesDialog
      v-model="showMoveFolderDialog"
      v-model:move-id="moveId"
      @moveSuccess="moveSuccess"/>

  <ConfirmDeleteFolderDialog v-model:delete-folder-id="deleteFolderId" v-model="showConfirmDeleteFolderDialog"/>

  <CreateFolderDialog
      @clear="clear"
      :update-obj="editData"
      @refresh="refresh"
      :parent-id="props.parentId"
      v-model="showCreateFolderDialog"/>

  <div class="assembly_folders_item" @click="addItemToFoldersCrumbs(props.dataObj)">
    <div class="route_line"></div>
    <div class="icon_view">
      <i class="iconfont-avatar" :class="`${props.dataObj.icon}`"/>
    </div>
    <div class="right_content">
      <span class="folder_name" v-text="props.dataObj.name"></span>
      <span class="nums"><span v-text="props.dataObj.img_count"></span>&nbsp;files,&nbsp;<span
          v-text="props.dataObj.count"></span>&nbsp;folders</span>
    </div>

    <div @click.stop>
      <el-dropdown trigger="click" class="more_icon_view">
        <el-icon class="more_icon">
          <more-filled/>
        </el-icon>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="showEditDialog(props.dataObj)">Edit</el-dropdown-item>
            <el-dropdown-item @click="showMoveToDialog(props.dataObj.id)">Move to</el-dropdown-item>
            <el-dropdown-item @click="showDeleteDialog(props.dataObj.id)">Delete</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

  </div>
</template>

<script setup>
import {useStore} from "vuex";
import useFolderItemOperation from "@/views/fileLibrary/hooks/useFolderItemOperation"
import ConfirmDeleteFolderDialog from '@/views/fileLibrary/components/ConfirmDeleteFolderDialog'
import CreateFolderDialog from '@/views/fileLibrary/components/CreateFolderDialog'
import MoveFolderOrFilesDialog from '@/views/fileLibrary/components/MoveFolderOrFilesDialog'

const $store = useStore()

const props = defineProps({
  dataObj: {
    type: Object,
    default: () => ({})
  },
  parentId: {
    type: Number,
    default: 0
  }
})

const addItemToFoldersCrumbs = (itemObj) => {
  $store.commit('FileLibrary/addItemToFoldersCrumbs', itemObj)
}

const {
  //删除
  showDeleteDialog, showConfirmDeleteFolderDialog, deleteFolderId,
  //移动
  showMoveToDialog, showMoveFolderDialog, moveId, moveSuccess,
  //修改
  showEditDialog, showCreateFolderDialog, refresh, editData, clear,
} = useFolderItemOperation()

</script>

<style scoped lang='scss'>
.assembly_folders_item {
  width: 202px;
  height: 80px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  background-color: white;

  .route_line {
    width: 138px;
    height: 20px;
    border: 1px solid var(--el-color-primary);
    border-bottom-left-radius: 10px;
    position: absolute;
    right: -1px;
    top: -10px;
  }

  .icon_view {
    padding: 25px 20px 0 20px;

    i {
      color: var(--el-color-primary);
    }
  }

  .right_content {
    display: flex;
    flex-direction: column;
    margin-top: 18px;

    .folder_name {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      display: inline-block;
      white-space: nowrap;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .nums {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
      margin-top: 6px;
    }
  }

  &:active {
    border-color: var(--el-color-primary);
  }

}

.more_icon_view {
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  right: 5px;
  top: 50%;
  color: rgba(0, 0, 0, 0.4);

  .more_icon {
    transform: rotate(90deg);
  }

  &:hover {
    color: #606266;
  }

}
</style>
