<template>
  <div>
    <el-dialog
        custom-class="previewDialog"
        v-model="dialogVisible"
        width="460px"
        :close-on-click-modal="false"
        @closed="handleCloseData"
    >

      <template #title>
        <div class="__title__">Move to</div>
      </template>

      <div class="crumbs">
        <template v-for="(item,index) in crumbs" :key="item.id">
          <div class="crumbs_item" @click="handleGotoFolder(index + 1)">{{ item.name }}</div>
          <span v-if="index !== crumbs.length - 1" class="splitLine">/</span>
        </template>
      </div>

      <div class="content_list_view">
        <template v-for="item in crumbs[crumbs.length - 1]?.sub" :key="item.id">
          <div class="content_list_view_item" @click="handleGotoNext(item,item.id === props.moveId)"
               :class="{'content_list_view_item_disabled': item.id === props.moveId}">
            <i class="iconfont-avatar" :class="`${item.icon}`"/>
            <span>{{ item.name }}<span style="margin-left: 10px;">{{
                item.id === props.moveId ? '(currentFolder)' : ''
              }}</span></span>
          </div>
        </template>

        <div class="not_has_next"
             v-show="!(!!crumbs[crumbs.length - 1]?.sub && crumbs[crumbs.length - 1]?.sub?.length > 0)">
          <svg t="1641990096791" fill="rgba(0, 0, 0, 0.5)" class="icon" viewBox="0 0 1137 1024" version="1.1"
               xmlns="http://www.w3.org/2000/svg"
               p-id="7012" width="30" height="30">
            <path
                d="M1080.952232 1024H57.009242a57.009242 57.009242 0 0 1-57.009242-57.009242V57.009242a57.009242 57.009242 0 0 1 57.009242-57.009242h1023.94299a57.009242 57.009242 0 0 1 57.009242 57.009242v910.152544a57.009242 57.009242 0 0 1-57.009242 56.838214zM341.371339 398.266563L170.68567 568.952232v284.419107h796.533125v-170.685669l-170.68567-170.68567-170.62866 170.68567-284.533126-284.419107z m512.05701-227.580893a114.018483 114.018483 0 1 0 114.018483 114.018483 114.018483 114.018483 0 0 0-114.018483-114.018483z"
                p-id="7013"></path>
          </svg>
          <span>This folder has no sub folders</span>
        </div>

      </div>

      <template #footer>
        <div class="assembly_dialog_create_folder_footer">
          <el-button :loading="loading" @click="handleMove" type="primary">Move</el-button>
        </div>
      </template>

    </el-dialog>
  </div>
</template>

<script setup>
import useDialogModelValue from "@/views/fileLibrary/hooks/useDialogModelValue";
import {ref, toRef, watch} from "vue";
import {useStore} from "vuex";
import {ElMessage} from "element-plus";
import {batchMovePicturesToFolders, moveAlbum} from "@/api/fileLibrary";

const store = useStore()
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  moveId: {
    type: Number,
    default: null
  },
  moveIds: {
    type: Array,
    default: () => ([])
  },
  folderOrFiles: {
    type: Boolean,
    default: false,// false = folder ; true = files
  }
})

const emit = defineEmits(['update:modelValue', 'update:moveId', 'moveSuccess'])

//弹窗open|close
const {dialogVisible} = useDialogModelValue(toRef(props, 'modelValue'), emit)

const loading = ref(false)
const handleMove = async () => {
  const afterMovingParentId = crumbs.value[crumbs.value.length - 1].id
  try {
    loading.value = true
    if (!props.folderOrFiles) {
      //folder
      await moveAlbum({
        albumId: props.moveId,//文件夹id
        parentId: afterMovingParentId//移动后的上级id
      })
    } else {
      //files
      await batchMovePicturesToFolders({
        albumId: afterMovingParentId,
        imgId: props.moveIds,
      })
    }
    emit('update:modelValue', false)
    emit('moveSuccess')
  } catch (e) {
    ElMessage.error(e)
  } finally {
    loading.value = false
  }
}

const crumbs = ref([])

watch(dialogVisible, () => {
  if (dialogVisible) {
    crumbs.value = []
    crumbs.value.push({
      id: 0,
      parent: 0,
      name: 'all',
      img_count: 0,//文件数量
      count: 0,//文件夹数量
      sub: store.state.FileLibrary.folderList
    })
  }
})

const handleGotoNext = (item, currentFolder) => {
  if (currentFolder) {
    return
  }
  crumbs.value.push(item)
}

const handleGotoFolder = (length) => {
  crumbs.value.length = length
}

const handleCloseData = () => {
  crumbs.value = []
  loading.value = false
  emit('update:moveId', null)
}

</script>

<style scoped lang='scss'>
.__title__ {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
}

.crumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  user-select: none;
  -webkit-user-drag: none;
  padding: 20px 30px 30px 30px;

  &_item {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin: 2px 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

  }

  .splitLine {
    margin: 0 5px;
  }

  &_item:last-child {
    color: rgba(0, 0, 0, 0.85);
    cursor: default;
    text-decoration: none;
  }
}

.content_list_view {
  overflow-y: auto;
  margin-bottom: 20px;

  &_item {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    -webkit-user-drag: none;

    i {
      margin-right: 10px;
      margin-left: 30px;
      color: var(--el-color-primary);
    }

    span {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 30px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

  }

  .not_has_next {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 15px;
    }
  }

  &_item_disabled {
    color: rgba(0, 0, 0, 0.5);
    cursor: not-allowed;

    i {
      margin-right: 10px;
      margin-left: 30px;
      color: rgba(0, 0, 0, 0.5);
    }

    &:hover {
      background-color: transparent;
    }
  }

}

:deep(.el-dialog__body) {
  padding: 0;
}

</style>
