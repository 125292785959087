<template>
  <div class="assembly_files_in_file_library_blank">
    <svg xmlns="http://www.w3.org/2000/svg" width="453.026" height="332.61" viewBox="0 0 453.026 332.61">
      <g id="组_206" data-name="组 206" transform="translate(-821.88 -225.745)">
        <g id="图片" transform="matrix(0.978, -0.208, 0.208, 0.978, 840.742, 398.912)">
          <rect id="矩形" class="cls-1" width="163" height="163" transform="translate(0.843 0.185)"/>
          <path id="路径" class="cls-2"
                d="M113.4,108H12.6A12.648,12.648,0,0,1,0,95.294V12.706A12.648,12.648,0,0,1,12.6,0H113.4A12.648,12.648,0,0,1,126,12.706V95.294A12.648,12.648,0,0,1,113.4,108Z"
                transform="translate(18.958 27.092)"/>
          <path id="路径-2" data-name="路径" class="cls-3"
                d="M126,48.913,119.7,41.2,81.154,2.325a7.8,7.8,0,0,0-11.108,0L48.328,24.195a1.521,1.521,0,0,1-1.078.5,1.4,1.4,0,0,1-1.078-.5L40.2,18.246a7.8,7.8,0,0,0-11.108,0L6.3,41.288,0,49V67.264A12.663,12.663,0,0,0,12.6,80H113.4A12.663,12.663,0,0,0,126,67.264V48.913"
                transform="translate(19.752 56.596)"/>
          <path id="路径-3" data-name="路径" class="cls-4" d="M0,8A8,8,0,1,0,8,0,8,8,0,0,0,0,8Z"
                transform="translate(36.679 41.654)"/>
          <path id="形状" class="cls-5"
                d="M3.334,22a3.308,3.308,0,1,1,0-6.616H61.667a3.308,3.308,0,1,1,0,6.616Zm0-15.383A3.331,3.331,0,0,1,0,3.308,3.331,3.331,0,0,1,3.334,0H32.5a3.331,3.331,0,0,1,3.334,3.308A3.331,3.331,0,0,1,32.5,6.617Z"
                transform="translate(41.545 100.103)"/>
        </g>
        <g id="图片备份" transform="matrix(0.848, 0.53, -0.53, 0.848, 925.365, 225)">
          <rect id="矩形-2" data-name="矩形" class="cls-1" width="47" height="47" transform="translate(0.12 0.804)"/>
          <path id="路径-4" data-name="路径" class="cls-6"
                d="M32.4,31H3.6A3.622,3.622,0,0,1,0,27.353V3.647A3.622,3.622,0,0,1,3.6,0H32.4A3.622,3.622,0,0,1,36,3.647V27.353A3.622,3.622,0,0,1,32.4,31Z"
                transform="translate(5.514 7.691)"/>
          <path id="路径-5" data-name="路径" class="cls-7"
                d="M36,14.063l-1.8-2.216L23.187.668a2.218,2.218,0,0,0-3.174,0L13.808,6.956A.433.433,0,0,1,13.5,7.1a.4.4,0,0,1-.308-.145l-1.705-1.71a2.218,2.218,0,0,0-3.174,0L1.8,11.87,0,14.087v5.252A3.63,3.63,0,0,0,3.6,23H32.4A3.63,3.63,0,0,0,36,19.338V14.063"
                transform="translate(5.089 16.673)"/>
          <path id="路径-6" data-name="路径" class="cls-8" d="M0,2.5A2.5,2.5,0,1,0,2.5,0,2.5,2.5,0,0,0,0,2.5Z"
                transform="translate(10.309 12.051)"/>
          <path id="形状-2" data-name="形状" class="cls-9"
                d="M.975,6A.943.943,0,0,1,0,5.1a.943.943,0,0,1,.975-.9h17.05A.943.943,0,0,1,19,5.1a.943.943,0,0,1-.975.9Zm0-4.2A.943.943,0,0,1,0,.9.943.943,0,0,1,.975,0H9.5a.943.943,0,0,1,.974.9.943.943,0,0,1-.974.9Z"
                transform="translate(11.098 29.678)"/>
        </g>
        <g id="图片2" transform="matrix(0.921, 0.391, -0.391, 0.921, 1173.098, 328.798)">
          <rect id="矩形-3" data-name="矩形" class="cls-1" width="110" height="110" transform="translate(0.878 0.653)"/>
          <path id="路径-7" data-name="路径" class="cls-10"
                d="M77.4,73H8.6A8.59,8.59,0,0,1,0,64.412V8.588A8.59,8.59,0,0,1,8.6,0H77.4A8.59,8.59,0,0,1,86,8.588V64.412A8.59,8.59,0,0,1,77.4,73Z"
                transform="translate(12.152 20.004)"/>
          <path id="路径-8" data-name="路径" class="cls-11"
                d="M86,33.016l-4.3-5.2L55.391,1.57a5.363,5.363,0,0,0-7.582,0L32.986,16.332a1.043,1.043,0,0,1-.736.339.961.961,0,0,1-.736-.339l-4.074-4.016a5.363,5.363,0,0,0-7.582,0L4.3,27.87,0,33.073V45.4A8.6,8.6,0,0,0,8.6,54H77.4A8.6,8.6,0,0,0,86,45.4V33.016"
                transform="translate(12.712 38.5)"/>
          <path id="路径-9" data-name="路径" class="cls-12" d="M0,5.5A5.5,5.5,0,1,0,5.5,0,5.5,5.5,0,0,0,0,5.5Z"
                transform="translate(24.583 29.054)"/>
          <path id="形状-3" data-name="形状" class="cls-13"
                d="M2.256,15a2.256,2.256,0,1,1,0-4.512H41.744a2.256,2.256,0,1,1,0,4.512Zm0-10.489A2.256,2.256,0,1,1,2.256,0H22a2.256,2.256,0,1,1,0,4.512Z"
                transform="translate(28.358 68.42)"/>
        </g>
        <g id="矩形-4" data-name="矩形" class="cls-14" transform="translate(956 298.071)">
          <rect class="cls-21" width="240" height="240"/>
          <rect class="cls-22" x="1.5" y="1.5" width="237" height="237"/>
        </g>
        <path id="形状-4" data-name="形状" class="cls-15" d="M14.054,60V34.909H25.946V60ZM0,34.909,20,0,40,34.909Z"
              transform="translate(1056 388.071)"/>
        <path id="路径_3" data-name="路径 3" class="cls-16" d="M5.9,56,1.057,33.278S-3.4,15.113,10.946,11.035,59,0,59,0"
              transform="translate(854 398.071)"/>
        <path id="路径_4" data-name="路径 4" class="cls-17" d="M0,0S47.038,51,101,51" transform="translate(824 310.071)"/>
        <path id="路径_4备份_2" data-name="路径 4备份 2" class="cls-18" d="M-54,6.056S-21.77,14.147,0,0"
              transform="translate(1266 466.071)"/>
        <path id="路径_4备份" data-name="路径 4备份" class="cls-19" d="M0,0S47.039,18.809,83,2.241"
              transform="translate(837 362.071)"/>
        <path id="路径_4备份_3" data-name="路径 4备份 3" class="cls-20" d="M-34,21S-10.091,16.318,0,0"
              transform="translate(1253.258 483.742) rotate(39)"/>
      </g>
    </svg>

    <el-upload
        :before-upload="handleBeforeUpload"
        action="/"
    >
      <el-button :loading="store.state.FileLibrary.uploadLoading" class="btn" type="primary">Upload</el-button>
    </el-upload>

    <div class="submit_files_in">
      <span>Submit files in</span>
      <span class="format">PNG</span>
      <span>or</span>
      <span class="format">JPEG</span>
      <span>format.</span>
    </div>

    <div class="tips">
      <span>Make sure you have read our</span>
      <a class="link">Terms of service</a>
      <span>and</span>
      <a class="link link_r_n">Acceptable Content Guidelines</a>
      <span>.</span>
    </div>

  </div>
</template>

<script setup>
import {useStore} from "vuex";
import useFileUpload from "@/views/fileLibrary/hooks/useFileUpload";

const store = useStore()
const {beforeUpload} = useFileUpload()

const handleBeforeUpload = (file) => {
  beforeUpload(file, {
    uploadSuccess: () => {
      store.dispatch('FileLibrary/getImageList', 1)
    },
  })
  return false
}

</script>

<style scoped lang='scss'>

.assembly_files_in_file_library_blank {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  svg {
    position: relative;
    left: -25px;
  }

  .btn {
    margin-top: 20px;
    width: 240px;
  }

  .submit_files_in {
    margin-top: 15px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);

    .format {
      color: rgba(0, 0, 0, 0.85);
      padding: 0 5px;
    }
  }

  .tips {
    margin-top: 40px;
    width: calc(100% - 30px);
    box-sizing: border-box;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.03);
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    padding: 30px;

    .link {
      color: #1EAB7F;
      text-decoration: underline;
      cursor: pointer;
      padding: 0 5px;
    }

    .link_r_n {
      padding-right: 0;
    }

  }

}

.cls-1 {
  opacity: 0;
}

.cls-2 {
  fill: #e4fffb;
}

.cls-3 {
  fill: #49ad3d;
}

.cls-4 {
  fill: #ff6823;
}

.cls-5 {
  fill: #29901d;
}

.cls-6 {
  fill: #fffcf1;
}

.cls-7 {
  fill: #84d1b7;
}

.cls-8 {
  fill: #ff8871;
}

.cls-9 {
  fill: #77c0a8;
}

.cls-10 {
  fill: #ffecec;
}

.cls-11 {
  fill: #dc7052;
}

.cls-12 {
  fill: #ff2379;
}

.cls-13 {
  fill: #8e522c;
}

.cls-14 {
  fill: #fff;
  stroke: rgba(0, 0, 0, 0.2);
  stroke-dasharray: 8 6;
}

.cls-14, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20 {
  stroke-miterlimit: 10;
}

.cls-14, .cls-17, .cls-18 {
  stroke-width: 3px;
}

.cls-15 {
  fill: rgba(0, 0, 0, 0.2);
}

.cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-22 {
  fill: none;
}

.cls-16, .cls-17, .cls-18, .cls-19, .cls-20 {
  stroke: #707070;
}

.cls-16, .cls-19, .cls-20 {
  stroke-width: 2px;
}

.cls-17, .cls-18, .cls-19, .cls-20 {
  stroke-linecap: round;
}

.cls-17, .cls-19 {
  opacity: 0.5;
}

.cls-18, .cls-20 {
  opacity: 0.3;
}

.cls-21 {
  stroke: none;
}

</style>
